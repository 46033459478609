import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/TexasMassageInfo-Prod/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Conclusion1 from './operational-conclusions/c1.mdx';
import Conclusion2 from './operational-conclusions/c2.mdx';
import Conclusion3 from './operational-conclusions/c3.mdx';
import Conclusion4 from './operational-conclusions/c4.mdx';
import Conclusion5 from './operational-conclusions/c5.mdx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "ii-conclusions---operational"
    }}>{`II. Conclusions - Operational`}</h1>
    <br />
    <Conclusion1 mdxType="Conclusion1" />
    <hr></hr>
    <br />
    <Conclusion2 mdxType="Conclusion2" />
    <hr></hr>
    <br />
    <Conclusion3 mdxType="Conclusion3" />
    <hr></hr>
    <br />
    <Conclusion4 mdxType="Conclusion4" /> 
    <hr></hr>
    <br />
    <Conclusion5 mdxType="Conclusion5" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      